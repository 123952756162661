<template>
  <div class="onboarding-layout" data-app>
    <HeaderNotification />
 
    <Navbar :on-logout="logout" hidden-subscription-badge />
    <main class="onboarding-content">
      <nuxt />
    </main>

    <whatsapp-support />
  </div>
</template>

<script>
import Navbar from '@/components/base/navbar'
import WhatsappSupport from '@/components/whatsapp-support'
import HeaderNotification from '@/components/base/header-notification'

import Mixpanel from '@/mixins/mixpanel'
import Sentry from '@/mixins/sentry'

export default {
  components: { Navbar, WhatsappSupport, HeaderNotification },
  mixins: [Mixpanel, Sentry],
  methods: {
    async logout() {
      try {
        this.$mixpanel.reset()
        await this.$auth.logout()

        const auth_strategy = window.localStorage.getItem('auth.strategy')
        const redirectPath =
          auth_strategy === 'chat' ? '/login/chat' : '/auth/signin'
        window.location = redirectPath

        localStorage.clear()
      } catch (err) {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.onboarding-content {
  max-width: 375px;
  margin: 0 16px;

  @include media-query(400px) {
    margin: 0 auto;
  }
}
::v-deep .v-menu__content {
  margin-top: 67px;
  border-radius: 8px;
}
</style>
