export const state = () => ({
  data: undefined
})

export const mutations = {
  set(state, data) {
    state.data = data
  }
}

export const actions = {
  async load({ commit }, zapId) {
    const response = await this.$axios.request({
      url: `machines/${zapId}/settings`
    })
    commit('set', response.data)
    return response
  },
  async update({ commit }, { zapId, data }) {
    return await this.$axios.request({
      method: 'put',
      url: `machines/${zapId}/settings`,
      data: data
    })
  }
}
