import Vue from 'vue'

import Vuetify, {
  VApp,
  VLayout,
  VSpacer,
  VFlex,
  VForm,
  VTooltip,
  VMenu,
  VSelect,
  VCheckbox,
  VCombobox,
  VChip,
  VIcon,
  VList,
  VListTile,
  VListTileActionText,
  VListTileAvatar,
  VListTileContent,
  VListTileSubTitle,
  VListTileTitle,
  VBtn,
  VDatePicker,
  VTextField,
  VTextarea,
  VProgressLinear,
  VProgressCircular,
  VListTileAction,
  VContainer,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VImg,
  VDivider,
  VAvatar,
  VDialog,
  VToolbar,
  VToolbarTitle,
  VNavigationDrawer,
  VSystemBar,
  VSubheader,
  VAutocomplete,
  VSlider,
  VRadioGroup,
  VRadio
} from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VApp,
    VContainer,
    VLayout,
    VSpacer,
    VFlex,
    VForm,
    VNavigationDrawer,
    VTooltip,
    VMenu,
    VSelect,
    VCheckbox,
    VCombobox,
    VChip,
    VIcon,
    VList,
    VListTile,
    VListTileAction,
    VListTileActionText,
    VListTileAvatar,
    VListTileContent,
    VListTileTitle,
    VBtn,
    VDatePicker,
    VTextField,
    VProgressLinear,
    VProgressCircular,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VImg,
    VDivider,
    VAvatar,
    VDialog,
    VToolbar,
    VToolbarTitle,
    VListTileSubTitle,
    VSystemBar,
    VSubheader,
    VAutocomplete,
    VTextarea,
    VSlider,
    VRadioGroup,
    VRadio
  }
})
