<template>
  <div>
    <div 
      ref="whatsappIcon"
      style="display:none;position:fixed;bottom:80px;right:40px;background-color:#25d366;border-radius:50px;z-index:1000;"
    >
      <v-tooltip top>
        <div slot="activator">
          <a
            style="display: block; width:60px;height:60px;color:#FFF;border-radius:50px;text-align:center;font-size:30px;box-shadow: 1px 1px 2px #888;"
            :href="whatsappLink" target="_blank" @click="registerClick"
          >
            <i style="margin-top:16px" class="fab fa-whatsapp" />
          </a>
        </div>
        <span>Falar com o suporte.</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  /*head() {
    return {
      script: [
        {
          src: '/assets/libs/zoho.js'
        }
      ]
    }
  },*/
  computed: {
    ...mapGetters(['loggedUser']),
    isFree() {
      return this.loggedUser?.is_free
    },
    whatsappLink() {
      //return 'https://czap.me/ZMhsib2DYe9ysiLbNaY'
      if (this.isFree) {
        return 'https://czap.me/6AclqLnhF8eP12rKv'
      } else {
        return 'https://czap.me/snkiDe0MudDJB2dcZPM'
        /* const message = encodeURIComponent(
          `Olá, preciso de ajuda com o Clickzap.\nMeu e-mail é ${
            this.loggedUser?.email
          }`
        )
        return `https://wa.me/5561981891700?text=${message}` */
      }
    }
  },
  mounted() {
    this.loadZohoIcon()
  },
  methods: {
    async registerClick() {
      this.$mixpanel.track('Clicked whatsapp support link')
    },
    loadZohoIcon(isRetry = false) {
      this.$refs.whatsappIcon.style.display = 'block'
      /*
      // if (this.loggedUser?.is_free) {
      //  this.$refs.whatsappIcon.style.display = 'block'
      // } else {
      setTimeout(() => {
        if (!showZohoSupport) {
          if (!isRetry) {
            return this.loadZohoIcon(true)
          }
          console.log('skip load zoho')
          return
        }
        showZohoSupport()
        setTimeout(() => {
          const zohoDesk = document.getElementById('zsiq_float')
          if (zohoDesk) {
            zohoDesk.style.display = 'block'
            zohoDesk.parentNode.style.marginBottom =
              this.$route.name == 'broadcast-new' ? '60px' : '0px'

            if (this.$route.query.openChat) {
              const chat = document.getElementById('zsiq_agtpic')
              if (chat) chat.click()
            }
          }
        }, 2000)
      }, 2000)
      // }
      */
    }
  }
}
</script>
