const wchat = require('../helpers/chat')

function filter_set(status, attendant, size, attendant_id) {
  let attendances = new Set()
  if (status) {
    if (status == 'open') {
      attendances = new Set(Object.keys(wchat.session.open_attendances))
    } else if (status == 'closed') {
      attendances = new Set(Object.keys(wchat.session.closed_attendances))
    } else if (status == 'me') {
      let attendant_chats =
        wchat.session.attendant_attendances[`${attendant_id}`]
      if (attendant_chats != undefined) {
        attendances = new Set(Object.keys(attendant_chats))
      }
    }
  }
  if (attendant) {
    let attendant_chats = wchat.session.attendant_attendances[`${attendant}`]
    if (attendant_chats != undefined) {
      attendances = new Set([
        ...attendances,
        ...new Set(Object.keys(attendant_chats))
      ])
    }
  }
  const reduced = Array.from(attendances)
    .slice(0, size + 20)
    .reduce((obj, keyf) => {
      obj[keyf] = wchat.session.chats[keyf]
      return obj
    }, {})
  return { chats: reduced, total: attendances.size }
}

function filter_array(search, status, tags, size, attendant_id) {
  const filtered = Object.keys(wchat.session.chats).filter(key => {
    let chat = wchat.session.chats[key]
    let tags_status = false
    if (tags.length && chat.chat_attendance) {
      let temp_tags = []
      chat.chat_attendance.tags.forEach(tag => {
        temp_tags.push(tag.name || tag)
      })
      tags_status = tags.some(tag => temp_tags.includes(tag))
      // tags.every(
      //   (i => v => (i = temp_tags.indexOf(v, i) + 1))(0)
      // )
    }
    let filter_status = false
    if (status) {
      if (status == 'wait') {
        filter_status = chat.attendance == null
      } else if (status == 'unread') {
        filter_status = chat.unreadCount > 0
      }
    }
    let search_status =
      (search != '' &&
        search != null &&
        chat.name.toLowerCase().includes(search.toLowerCase())) ||
      (search != '' && search != null && chat.id.includes(search))
    if (tags.length && status && (search == '' || search == null))
      return filter_status && tags_status
    if (tags.length && status && search != '' && search != null)
      return filter_status && tags_status && search_status
    if (tags.length && (search == '' || search == null)) return tags_status
    if (tags.length && search != '' && search != null)
      return tags_status && search_status
    if (status && search != '' && search != null)
      return filter_status && search_status
    if (tags.length && (search == '' || search == null)) return tags_status
    if (tags.length) return tags_status
    if (search != '' && search != null) return search_status
    return filter_status || tags_status || search_status
  })
  const reduced = filtered.slice(0, size + 20).reduce((obj, keyf) => {
    obj[keyf] = wchat.session.chats[keyf]
    return obj
  }, {})
  return { chats: reduced, total: filtered.length }
}

function filter_chats(search, status, tags, attendant, size, attendant_id) {
  if (
    Object.keys(wchat.session.chats).length > 0 &&
    ((search != '' && search != null) || status || tags.length > 0 || attendant)
  ) {
    const filtered = Object.keys(wchat.session.chats).filter(key => {
      let chat = wchat.session.chats[key]
      let tags_status = false
      if (tags.length && chat.chat_attendance) {
        let temp_tags = []
        chat.chat_attendance.tags.forEach(tag => {
          temp_tags.push(tag.name || tag)
        })
        tags_status = tags.some(tag => temp_tags.includes(tag))
        // tags.every(
        //   (i => v => (i = temp_tags.indexOf(v, i) + 1))(0)
        // )
      }
      let filter_status = false
      if (status) {
        if (status == 'open') {
          filter_status = chat.attendance && chat.attendance.closed_at == null
        } else if (status == 'closed') {
          filter_status = chat.attendance && chat.attendance.closed_at != null
        } else if (status == 'unread') {
          filter_status = chat.unreadCount > 0
        } else if (status == 'wait') {
          filter_status =
            chat.attendance == null ||
            (chat.attendance &&
              chat.attendance.closed_at == null &&
              chat.attendance.created_at == null &&
              chat.unreadCount > 0)
        } else if (status == 'me') {
          filter_status =
            chat.attendance &&
            chat.attendance.attendant_uuid == attendant_id &&
            chat.attendance.closed_at == null
        }
      }
      let attendant_status =
        chat.attendance &&
        !chat.attendance.closed_at &&
        chat.attendance.attendant_uuid == attendant
      let search_status =
        (search != '' &&
          search != null &&
          chat.name.toLowerCase().includes(search.toLowerCase())) ||
        (search != '' && search != null && chat.id.includes(search))
      if (
        tags.length &&
        status &&
        attendant &&
        (search == '' || search == null)
      )
        return filter_status && tags_status && attendant_status
      if (tags.length && status && attendant && search != '' && search != null)
        return filter_status && tags_status && attendant_status && search_status
      if (tags.length && status && (search == '' || search == null))
        return filter_status && tags_status
      if (tags.length && status && search != '' && search != null)
        return filter_status && tags_status && search_status
      if (status && tags.length) return filter_status && tags_status
      if (tags.length && attendant) return tags_status && attendant_status
      if (attendant && status) return attendant_status && filter_status
      if (status && search != '' && search != null)
        return filter_status && search_status
      if (tags.length && search != '' && search != null)
        return tags_status && search_status
      if (attendant && search != '' && search != null)
        return attendant_status && search_status
      return filter_status || tags_status || attendant_status || search_status
    })
    const reduced = filtered.slice(0, size + 20).reduce((obj, keyf) => {
      obj[keyf] = wchat.session.chats[keyf]
      return obj
    }, {})
    return { chats: reduced, total: filtered.length }
  } else {
    return {
      chats: wchat.session.getChats(size),
      total: Object.keys(wchat.session.chats).length
    }
  }
}

// function filter_chats(search, status, tags, attendant, size, attendant_id) {
//   // perfome_array(
//   //   status,
//   //   attendant,
//   //   attendant_id,
//   //   search,
//   //   tags,
//   //   size,
//   //   wchat.session.chats
//   // )
//   // perfome_set(status, attendant, attendant_id, size)
//   let chats_filtered = { chats: {}, total: 0 }
//   if (
//     Object.keys(wchat.session.chats).length > 0 &&
//     ((status && status != 'wait' && status != 'unread') ||
//       attendant ||
//       attendant)
//   ) {
//     chats_filtered = filter_set(status, attendant, size, attendant_id)
//   } else if (
//     Object.keys(wchat.session.chats).length > 0 &&
//     (status || (search != '' && search != null) || tags.length > 0)
//   ) {
//     chats_filtered = filter_array(search, status, tags, size, attendant_id)
//   } else {
//     return {
//       chats: wchat.session.getChats(size),
//       total: Object.keys(wchat.session.chats).length
//     }
//   }
//   return {
//     chats: chats_filtered.chats,
//     total: chats_filtered.total
//   }
// }

export const getters = {
  chats_filtereds: state => search => {
    if (wchat.session.chats && search && search != '') {
      const filtered = Object.keys(wchat.session.chats).filter(key => {
        return wchat.session.chats[key].name
          .toLowerCase()
          .includes(search.toLowerCase())
      })
      const reduced = filtered.slice(0, 20).reduce((obj, keyf) => {
        obj[keyf] = wchat.session.chats[keyf]
        return obj
      }, {})
      return reduced
    }
    return state.chats
  },
  is_assigned: state => (chatId, attendant_id) => {
    if (wchat.session.attendances) {
      const filtered = Object.keys(wchat.session.attendances).filter(key => {
        let attendance = wchat.session.attendances[key]
        return attendance && attendance.key == chatId
      })
      return filtered
    }
    return []
  },
  get_chat_attendance: state => chatId => {
    return wchat.session.getChatAttendance(chatId)
  }
}

export const state = () => ({
  settings: {},
  chats: new Map(),
  chat: undefined,
  status: {
    batteryLevel: 100,
    connected: true,
    loggedIn: true
  },
  loadingLead: false,
  loadingChat: false,
  loadingMessageChat: false,
  loadingChats: true,
  showAttendants: false,
  chat_id_received: undefined,
  chat_is_online: undefined,
  ip: undefined,
  refreshing: false,
  me: {
    name: '',
    phone: '',
    profilePicThumbObj: ''
  },
  refreshingChatId: undefined,
  attendants: [],
  attendant: {},
  attendances: {},
  is_assigned: false,
  chat_tags: [],
  filter_search: '',
  filter_chat_status: null,
  filter_chat_tags: [],
  filter_chat_attendants: null,
  more_to_load: true,
  attendants_status: {},
  total_chats: 0,
  app_version: 'full'
})

export const mutations = {
  setAttendant(state, attendant) {
    state.attendant = attendant
  },
  setFilterSearch(state, search) {
    state.filter_search = search
  },
  setFilterStatus(state, status) {
    state.filter_chat_status = status
  },
  setFilterAttendant(state, attendant) {
    state.filter_chat_attendants = attendant
  },
  setFilterTags(state, tags) {
    state.filter_chat_tags = tags
  },
  setChatIsOnLine(state, chat_is_online) {
    state.chat_is_online = chat_is_online
  },
  setShowAttendants(state, status) {
    state.showAttendants = status
    state.total_chats = Object.keys(wchat.session.chats).length
  },
  setAppVersion(state, version_app) {
    state.app_version = version_app
    window.localStorage.setItem('version_app', version_app)
  },
  setChats(state, chats) {
    wchat.session.setChats(
      chats,
      window.localStorage.getItem('version_app') == 'lite'
    )
    state.chats = wchat.session.getChats(0)
    state.total_chats = Object.keys(chats).length
    state.loadingChats = false
  },
  setFilteredChats(state, chats) {
    state.chats = {}
    state.chats = chats
    state.more_to_load =
      Object.keys(state.chats).length < Object.keys(wchat.session.chats).length
  },
  setTotalChats(state, total) {
    state.total_chats = total
  },
  setMe(state, me) {
    state.me = me
    wchat.session.setMe(me)
  },
  setRefreshing(state, [refreshing, chatId]) {
    state.refreshing = refreshing
    state.refreshingChatId = chatId
  },
  setStatus(state, status) {
    state.status = status
    wchat.session.setStatus(status)
  },
  setBattery(state, battery) {
    state.battery = battery
  },
  setLoadingChat(state, loading) {
    state.loadingChat = loading
  },
  setLoadingMessageChat(state, loading) {
    state.loadingMessageChat = loading
  },
  setLoadingChats(state, loading) {
    state.loadingChats = loading
  },
  clearChat(state, chat) {
    if (chat && chat.id) {
      wchat.session.clearChat(chat.id)
    }
  },
  setChat(state, chat) {
    if (chat && chat.id) {
      wchat.session.setReadCount(chat.id, 0)
      state.chat = wchat.session.setCurrentChatId(chat.id)
    } else {
      state.chat = undefined
    }
  },
  clearChats(state) {
    wchat.session.clearChats()
    wchat.session.setCurrentChatId(undefined)
  },
  updateChat(state, chat) {
    wchat.session.refreshChatMessages(chat.id)
  },
  setMessages(state, [chatId, msgs]) {
    wchat.session.setChatMessages(chatId, msgs)
    state.chats[chatId].msgs = {}
    state.chats[chatId].msgs = msgs.data
    state.chats[chatId].preloadTotalMsgs = msgs.total
  },
  setMessage(state, message) {
    let chat = state.chats[message.chatId]
    if (chat) {
      state.chat.msgs[message.id] = {}
      state.chat.msgs[message.id] = message
    }
  },
  setMessageLoaded(state, [msg, loaded]) {
    wchat.session.setChatMessageLoaded(msg, loaded)
    let chat = state.chats[msg.chatId]
    if (chat) {
      let message = chat.msgs[msg.id]
      message.loaded = loaded
    }
  },
  setChatMessageInput(state, [chatId, msg]) {
    wchat.session.setChatMessageInput(chatId, msg)
    let chat = state.chats[chatId]
    if (chat) {
      chat.chat_input = msg
    }
  },
  setAck(state, message) {
    wchat.session.setAckMessage(message)
    wchat.session.refreshChatMessages(message.chatId)
  },
  setLoadingLead(state, loading) {
    state.loadingLead = loading
  },
  setIp(state, ip) {
    state.ip = ip
  },
  loadMoreChats(state) {
    let size = Object.keys(state.chats).length
    state.chats = {}
    let chats = filter_chats(
      state.filter_search,
      state.filter_chat_status,
      state.filter_chat_tags,
      state.filter_chat_attendants,
      size,
      state.attendant.attendant_id
    )
    state.chats = chats.chats || {}
    state.total_chats = chats.total || 0
    state.more_to_load =
      Object.keys(state.chats).length < Object.keys(wchat.session.chats).length
  },
  loadTopChats(state) {
    state.chats = {}
    state.chats = wchat.session.getChats(20)
    state.total_chats = Object.keys(wchat.session.chats).length
  },
  setProfilePicThumbObj(state, [chatId, profilePicThumbObj]) {
    wchat.session.setProfilePicThumbObj(chatId, profilePicThumbObj)
  },
  setChatMessages(state, [chatId, data]) {
    wchat.session.setChatMessages(chatId, data)
  },
  setChatReadCount(state, [chatId, value]) {
    wchat.session.setReadCount(chatId, value)
  },
  addChatMessage(state, message) {
    let chat = wchat.session.getChat(message.chatId)
    let size = Object.keys(state.chats).length
    state.chat_id_received = message.chatId
    if (chat) {
      wchat.session.addChatMessage(message)
      wchat.session.refreshChats(message.chatId, null)
    } else {
      let msgs = {}
      msgs[message.id] = message
      chat = {
        archive: null,
        id: message.chat.id,
        name:
          message.chat && message.chat.contact
            ? message.chat.contact.pushname
            : message.chat.id
              ? message.chat.id.split('@')[0]
              : '',
        notSpam: true,
        pendingMsgs: {},
        timestamp: message.timestamp,
        unreadCount: message.chat.unreadCount + 1,
        msgs: msgs,
        profilePicThumbObj: null
      }
      wchat.session.refreshChats(chat.id, chat)
    }
    state.chats = {}
    state.chats = wchat.session.getChats(size)
    if (state.chat && message.chatId == state.chat.id) {
      state.chat = wchat.session.getChat(message.chatId)
    }

    if (
      state.filter_search != '' ||
      state.filter_chat_status ||
      state.filter_chat_tags.length
    ) {
      state.chats = {}
      let chats = filter_chats(
        state.filter_search,
        state.filter_chat_status,
        state.filter_chat_tags,
        state.filter_chat_attendants,
        size,
        state.attendant.attendant_id
      )
      state.chats = chats.chats || {}
      state.total_chats = chats.total || 0
    }
    state.more_to_load =
      Object.keys(state.chats).length < Object.keys(wchat.session.chats).length
  },
  setAttendants(state, attendants) {
    state.attendants = attendants
  },
  setAttendances(state, attendances) {
    wchat.session.setAttendances(attendances)
  },
  setAttendancesKeys(state, attendances) {
    wchat.session.setAttendancesKeys(attendances)
  },
  setChatAttendances(state, chats) {
    wchat.session.setChatAttendances(chats)
  },
  setAttendance(state, attendance) {
    if (attendance) {
      let size = Object.keys(state.chats).length
      wchat.session.addAttendance(attendance)
      state.chats = {}
      let chats = filter_chats(
        state.filter_search,
        state.filter_chat_status,
        state.filter_chat_tags,
        state.filter_chat_attendants,
        size,
        state.attendant.attendant_id
      )
      state.chats = chats.chats || {}
      state.total_chats = chats.total || 0
      state.more_to_load =
        Object.keys(state.chats).length <
        Object.keys(wchat.session.chats).length
      if (state.chat && attendance.key == state.chat.id) {
        state.chat = undefined
        state.chat = wchat.session.getChat(attendance.key)
      }
    }
  },
  setChatAttendance(state, chat) {
    if (chat) {
      let size = Object.keys(state.chats).length
      wchat.session.addChatAttendance(chat)
      state.chats = {}
      if (
        state.filter_search != '' ||
        state.filter_chat_status ||
        state.filter_chat_tags.length
      ) {
        let chats = filter_chats(
          state.filter_search,
          state.filter_chat_status,
          state.filter_chat_tags,
          state.filter_chat_attendants,
          size,
          state.attendant.attendant_id
        )
        state.chats = chats.chats || {}
        state.total_chats = chats.total || 0
      } else {
        state.chats = wchat.session.getChats(size)
      }
      state.more_to_load =
        Object.keys(state.chats).length <
        Object.keys(wchat.session.chats).length
    }
  },
  setIsAssigned(state, isAssigned) {
    state.is_assigned = isAssigned
  },
  setChatTags(state, tags) {
    state.chat_tags = tags
  },
  setChatSettings(state, settings) {
    state.settings = settings
  },
  setChatLeads(state, leads) {
    wchat.session.setChatAttendances(leads)
  },
  setStatusAttendant(state, data) {
    let temp = Object.assign({}, state.attendants_status)
    state.attendants_status = {}
    state.attendants_status = temp
    state.attendants_status[`${data.attendant_id}`] = data
  },
  setStatusAttendants(state, data) {
    state.attendants_status = {}
    Object.keys(data).forEach(key => {
      state.attendants_status[`${key}`] = {
        status: data[key],
        attendant_id: key
      }
    })
  }
}
export const actions = {
  async get({ state, commit }) {
    commit('setLoadingChats', true)
    const resp = await this.$axios.get(`messages/all`, {
      baseURL: state.ip || 'http://localhost:7010'
    })
    commit('setChats', resp.data.data)
  },
  async set({ state, commit }, chat) {
    if (chat) {
      commit('setChat', chat)
      commit('setLoadingChat', true)
      if (
        wchat.session.getChat(chat.id) &&
        Object.keys(wchat.session.getChat(chat.id).msgs).length < 20
      ) {
        const res = await this.$axios.get(`getMessage?chatId=${chat.id}`, {
          baseURL: state.ip || 'http://localhost:7010'
        })
        commit('setChatMessages', [chat.id, res.data])
      }

      if (!chat.profilePicThumbObj) {
        let contact = await this.$axios.get(`contact?chatId=${chat.id}`, {
          baseURL: state.ip || 'http://localhost:7010'
        })
        if (contact.data)
          commit('setProfilePicThumbObj', [
            chat.id,
            contact.data.profilePicThumbObj
          ])
      }
      commit('setChatReadCount', [chat.id, 0])
    }
    setTimeout(() => {
      commit('setLoadingChat', false)
    }, 1)
  },
  async update_chat({ state, commit }, chat) {
    if (chat) {
      commit('updateChat', chat)
      await this.$axios.put(
        `message?chatId=${chat.id}`,
        {},
        {
          baseURL: state.ip || 'http://localhost:7010'
        }
      )
    }
  },
  async addNewMessage({ commit }, message) {
    if (message) {
      commit('setLoadingMessageChat', true)
      commit('addChatMessage', message)
      setTimeout(() => {
        commit('setLoadingMessageChat', false)
      }, 1000)
    }
  },
  setAckMessage({ state, commit }, message) {
    commit('setAck', message)
  },
  setIp({ commit }, ip) {
    commit('setIp', ip)
  },
  async refreshChat({ state, commit }, [chatId]) {
    try {
      let total = Object.keys(state.chats[chatId].msgs).length
      let refresh =
        total + 10 >= state.chats[chatId].preloadTotalMsgs || total < 6
      commit('setRefreshing', [true, chatId])
      const res = await this.$axios.get(
        `getMessage?chatId=${chatId}&refresh=${refresh}&totalCurrentMsgs=${total}`,
        {
          baseURL: state.ip || 'http://localhost:7010'
        }
      )
      commit('setMessages', [res.data.id, res.data])
    } catch (error) {
      commit('setMessages', [chatId, {}])
    } finally {
      setTimeout(() => {
        commit('setRefreshing', [false, chatId])
      }, 2000)
    }
  },
  async sendMsgChat({ state }, message) {
    return await this.$axios.post(`message`, message, {
      baseURL: state.ip || 'http://localhost:7010'
    })
  },
  async simulateTyping({ state }, typing) {
    return await this.$axios.post(`simulateTyping`, typing, {
      baseURL: state.ip || 'http://localhost:7010'
    })
  },
  async sendSeen({ state }, [chatId]) {
    let chat = wchat.session.getChat(chatId)
    if (chat.unreadCount > 0) {
      return await this.$axios.put(
        `message?chatId=${chatId}`,
        {},
        {
          baseURL: state.ip || 'http://localhost:7010'
        }
      )
    }
  },
  async isChatOnline({ state, commit }) {
    if (state.chat) {
      let res = await this.$axios.get(`isChatOnline?chatId=${state.chat.id}`, {
        baseURL: state.ip || 'http://localhost:7010'
      })
      commit('setChatIsOnLine', { chatId: state.chat.id, isOnline: res.data })
    }
  },
  async getStatus({ state, commit }) {
    let res = await this.$axios.get(`status`, {
      baseURL: state.ip || 'http://localhost:7010'
    })
    commit('setStatus', res.data)
  },
  async getImage({ commit, state }, [msgId]) {
    return await this.$axios.get(`image?msgId=${msgId}`, {
      baseURL: state.ip || 'http://localhost:7010'
    })
  },
  async sendImage({ commit, state }, data) {
    return await this.$axios.post(`image`, data, {
      baseURL: state.ip || 'http://localhost:7010'
    })
  },
  async sendAudio({ commit, state }, data) {
    return await this.$axios.post(`audio`, data, {
      baseURL: state.ip || 'http://localhost:7010'
    })
  },
  async sendVideo({ commit, state }, data) {
    return await this.$axios.post(`video`, data, {
      baseURL: state.ip || 'http://localhost:7010'
    })
  },
  async sendFile({ commit, state }, data) {
    return await this.$axios.post(`file`, data, {
      baseURL: state.ip || 'http://localhost:7010'
    })
  },
  async getAudio({ commit, state }, [msgId]) {
    return await this.$axios.get(`audio?msgId=${msgId}`, {
      baseURL: state.ip || 'http://localhost:7010'
    })
  },
  async getMeFromDriver({ state, commit }, [machine_id]) {
    if (!state.me.name) {
      let res = await this.$axios.get(`me`, {
        baseURL: state.ip || 'http://localhost:7010'
      })
      let me = res.data
      commit('setMe', me)
    }
  },
  async getMeFromApi({ state, commit }, [machine_id]) {
    const resp = await this.$axios.request({
      method: 'get',
      url: '/chat/me',
      params: { machine_id: machine_id }
    })
    if (resp.data) {
      commit('setMe', resp.data)
    }
  },
  setStatus({ state, commit }, status) {
    commit('setStatus', status)
  },
  setBattery({ state, commit }, battery) {
    commit('setBattery', battery)
  },
  setLoadingLead({ commit }, [loading]) {
    commit('setLoadingLead', loading)
  },
  loadMoreChats({ state, commit }) {
    commit('loadMoreChats')
  },
  clear({ commit }) {
    commit('clearChats')
  },
  setMessageLoaded({ commit }, [msg, loaded]) {
    commit('setMessageLoaded', [msg, loaded])
  },
  setChatMessageInput({ commit }, [chatId, msg]) {
    commit('setChatMessageInput', [chatId, msg])
  },
  setMessage({ commit }, msg) {
    commit('setMessage', msg)
  },
  filteredChats({ state, commit }) {
    let size = Object.keys(state.chats).length
    if (
      wchat.session.chats &&
      ((state.filter_search != '' && state.filter_search != null) ||
        state.filter_chat_status ||
        state.filter_chat_tags.length ||
        state.filter_chat_attendants)
    ) {
      let reduced = filter_chats(
        state.filter_search,
        state.filter_chat_status,
        state.filter_chat_tags,
        state.filter_chat_attendants,
        size,
        state.attendant.attendant_id
      )
      commit('setFilteredChats', reduced.chats || {})
      commit('setTotalChats', reduced.total || 0)
    } else {
      commit('loadTopChats')
    }
  },
  async create_attendant({ state, commit }, data) {
    const resp = await this.$axios.request({
      method: 'post',
      url: '/chat/attendants',
      data: data
    })
    return resp
  },
  async removeAttendant({ state, commit }, [id]) {
    const resp = await this.$axios.request({
      method: 'delete',
      url: '/chat/attendants',
      params: { id: id }
    })
    return resp.data
  },
  async updateAttendant({ state, commit }, data) {
    const resp = await this.$axios.request({
      method: 'put',
      url: `/chat/attendants`,
      data: data
    })
    return resp.data
  },
  async create_tag({ state, commit }, data) {
    const payload = {...data, name: data.name.replace(/[^\w\s]/gi, '')}
    const resp = await this.$axios.request({
      method: 'post',
      url: '/chat/tags',
      data: payload
    })
    return resp
  },
  async loadAttendants({ state, commit }, [machine_id]) {
    const resp = await this.$axios.request({
      method: 'get',
      url: '/chat/attendants',
      params: { machine_id: machine_id }
    })
    commit('setAttendants', resp.data)
  },
  async loadAttendances({ state, commit }, [machine_id]) {
    const resp = await this.$axios.request({
      method: 'get',
      url: `/chat/attendances`,
      params: { machine_id: machine_id }
    })
    commit('setAttendances', resp.data)
  },
  async loadAttendancesKeys({ state, commit }, [machine_id]) {
    const resp = await this.$axios.request({
      method: 'get',
      url: `/chat/attendances/keys`,
      params: { machine_id: machine_id }
    })
    commit('setAttendancesKeys', resp.data)
  },
  async loadChats({ state, commit }, [machine_id]) {
    const resp = await this.$axios.request({
      method: 'get',
      url: `/chats/${machine_id}`
    })
    commit('setChatAttendances', resp.data)
  },
  async loadTags({ state, commit }, [machine_id]) {
    try {
      const resp = await this.$axios.request({
        method: 'get',
        url: '/chat/tags',
        params: { machine_id: machine_id }
      })
      commit('setChatTags', resp.data)
      return resp.data
    } catch (error) {
      return []
    }
  },
  async getChat({ state, commit }, [machine_id, key]) {
    const resp = await this.$axios.request({
      method: 'get',
      url: `/chats/${key}/machine/${machine_id}`
    })
    return resp.data
  },
  async createAttendance({ state, commit }, data) {
    const resp = await this.$axios.request({
      method: 'post',
      url: '/chat/attendances',
      data: data
    })
    commit('setAttendance', resp.data)
    return resp.data
  },
  async assignTag({ state, commit }, data) {
    const resp = await this.$axios.request({
      method: 'post',
      url: '/chat/tags/assign',
      data: data
    })
    commit('setChatAttendance', resp.data)
    return resp.data
  },
  async closeAttendance({ commit }, filter) {
    const resp = await this.$axios.request({
      method: 'delete',
      url: '/chat/attendances',
      params: filter
    })
    commit('setAttendance', resp.data)
    return resp.data
  },
  setShowAttendants({ commit }, [status]) {
    commit('setShowAttendants', status)
  },
  isAssigned({ commit }, [chatId, attendant_id]) {
    if (wchat.session.attendances) {
      const filtered = Object.keys(wchat.session.attendances).filter(key => {
        let attendance = wchat.session.attendances[key]
        return attendance != undefined && attendance.key == chatId
      })
      let result = filtered.length
        ? wchat.session.attendances[filtered[0]]
        : null
      commit('setIsAssigned', result)
      return result
    } else {
      commit('setIsAssigned', null)
      return null
    }
  },
  async importLead({ state, commit }, [lead, chatId]) {
    if (lead.name == lead.phone) {
      let contact = await this.$axios.get(`contact?chatId=${chatId}`, {
        baseURL: state.ip || 'http://localhost:7010'
      })
      if (contact.data && contact.data.pushname)
        lead.name = contact.data.pushname
    }
    return await this.$axios.request({
      method: 'put',
      url: `lead/import`,
      data: lead
    })
  },
  async getStatusAttendant({ state, commit }) {
    const response = await this.$axios.get(`/attendant/status`, {
      baseURL: state.ip || 'http://localhost:7010'
    })
    commit('setStatusAttendants', response.data)
    return response
  },
  async getChatSettings({ commit }, [machine_id]) {
    const resp = await this.$axios.request({
      method: 'get',
      url: `/chat/settings`,
      params: { machine_id: machine_id }
    })
    commit('setChatSettings', resp.data)
    return resp.data
  },
  async getChatLeads({ commit }, [machine_id]) {
    const resp = await this.$axios.request({
      method: 'get',
      url: `/leads/chat`,
      params: { machine_id: machine_id }
    })
    commit('setChatAttendances', resp.data)
    return resp.data
  },
  async update_chat_settings({ commit }, data) {
    const resp = await this.$axios.request({
      method: 'put',
      url: '/chat/settings',
      data: data
    })
    commit('setChatSettings', resp.data)
    return resp.data
  },
  setFilters(
    { commit },
    [search, filter_attendants, filter_chat_tags, filter_chat_attendants]
  ) {
    commit('setFilterSearch', search)
    commit('setFilterStatus', filter_attendants)
    commit('setFilterTags', filter_chat_tags)
    commit('setFilterAttendant', filter_chat_attendants)
  },
  setAttendant({ commit }, attendant) {
    commit('setAttendant', attendant)
  },
  emitChanges({ state }, change) {
    window.SOCKET.emit('refresh_chats', {
      attendant_id: state.attendant.attendant_id,
      data: change
    })
  },
  async isWhatsApp({ state, commit }, [chatId]) {
    let res = await this.$axios.get(`/contact/query?chatId=${chatId}`, {
      baseURL: state.ip || 'http://localhost:7010'
    })
    return res.data
  },
  setAttendance({ commit }, data) {
    commit('setAttendance', data)
  },
  setChatSettings({ commit }, data) {
    commit('setChatSettings', data)
  },
  setChatAttendance({ commit }, data) {
    commit('setChatAttendance', data)
  },
  setStatusAttendant({ commit }, data) {
    commit('setStatusAttendant', data)
  },
  setAppVersion({ commit }, [version_app]) {
    commit('setAppVersion', version_app)
  }
}
