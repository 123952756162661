const { session } = require('../helpers/livechat')
const livechat_v2 = require('../helpers/livechat_v2')
const sessionV2 = livechat_v2.session

export const state = () => ({
  me: {
    name: '-',
    phone: '-',
    profilePicThumbObj: ''
  },
  status: {
    is_logged_in: true,
    is_connected: true
  },
  battery: null,
  tags: [],
  attendants: [],
  attendantsObj: {},
  settings: {},
  chats: undefined,
  attendances: [],
  attendancesKeys: [],
  chatLeads: {},
  contactView: false,
  loadingStatus: 'pending',
  loadingInfo: {},
  loadingLead: false,
  lastReceivedMessage: null,
  lastReceivedMedia: null,
  total_chats: 0,
  total_groups: 0,
  loadingFilter: false,
  loadingChatLeads: false,
  audioStatus: 'active'
})

export const mutations = {
  setMe(state, me) {
    state.me = me
  },
  setStatus(state, status) {
    state.status = status
  },
  setLoadingStatus(state, loadingStatus) {
    state.loadingStatus = loadingStatus
  },
  setLoadingInfo(state, loadingInfo) {
    state.loadingInfo = loadingInfo
  },
  setBattery(state, battery) {
    state.battery = battery
  },
  setChats(state, chats) {
    state.chats = chats
  },
  setFilterLoading(state, value) {
    state.loadingFilter = value
  },
  setChatLeads(state, chatLeads) {
    state.chatLeads = chatLeads
  },
  setLoadingChatLeads(state, value) {
    state.loadingChatLeads = value
  },
  setAttendants(state, attendants) {
    state.attendants = attendants
  },
  setAttendantsObj(state, attendantsObj) {
    state.attendantsObj = attendantsObj
  },
  setTags(state, tags) {
    state.tags = tags
  },
  setAttendances(state, attendances) {
    state.attendances = attendances
  },
  setAttendancesKeys(state, attendancesKeys) {
    state.attendancesKeys = attendancesKeys
  },
  showContactView(state, value) {
    state.contactView = value
  },
  setLoadingLead(state, loading) {
    state.loadingLead = loading
  },
  setLastReceivedMessage(state, lastReceivedMessage) {
    state.lastReceivedMessage = lastReceivedMessage
  },
  setLastReceivedMedia(state, lastReceivedMedia) {
    state.lastReceivedMedia = lastReceivedMedia
  },
  setChatSettings(state, settings) {
    state.settings = settings
  },
  setTotalChats(state, total) {
    state.total_chats = total
  },
  setTotalGroups(state, total) {
    state.total_groups = total
  },
  setAudioStatus(state, audioStatus) {
    state.audioStatus = audioStatus
  }
}

export const actions = {
  async importLead(store, lead) {
    return await this.$axios.request({
      method: 'put',
      url: `lead/import`,
      data: lead
    })
  },
  async loadAttendants({ commit }, machine_id) {
    const resp = await this.$axios.request({
      method: 'get',
      url: '/chat/attendants',
      params: { machine_id: machine_id }
    })
    commit('setAttendants', resp.data)

    let attendantsObj = {}
    for (let attendant of resp.data) {
      attendantsObj[attendant.id] = attendant
    }
    commit('setAttendantsObj', attendantsObj)
    return resp
  },
  async getChatSettings({ commit }, machine_id) {
    const resp = await this.$axios.request({
      method: 'get',
      url: `/chat/settings`,
      params: { machine_id: machine_id }
    })
    commit('setChatSettings', resp.data)
    return resp.data
  },
  async updateChatSettings({ commit }, data) {
    const resp = await this.$axios.request({
      method: 'put',
      url: '/chat/settings',
      data: data
    })
    commit('setChatSettings', resp.data)
    return resp.data
  },
  async loadAttendances({ dispatch, commit }, machine_id) {
    commit('setLoadingChatLeads', true)
    const resp = await this.$axios.request({
      method: 'get',
      url: `/chat/attendances`,
      params: { machine_id: machine_id }
    })
    commit('setAttendances', resp.data)
    sessionV2.setAttendances(resp.data)
    dispatch('refreshChats', {})
  },
  async createAttendance({ state, commit }, data) {
    const resp = await this.$axios.request({
      method: 'post',
      url: '/chat/attendances',
      data: data
    })
    // commit('setAttendance', resp.data)
    return resp.data
  },
  async closeAttendance({ commit }, filter) {
    const resp = await this.$axios.request({
      method: 'delete',
      url: '/chat/attendances',
      params: filter
    })
    // commit('setAttendance', resp.data)
    return resp.data
  },
  async refreshChats({ commit, rootState }, data) {
    const version = data?.version
    const isFilter = data?.isFilter
    if (isFilter) commit('setFilterLoading', true)

    commit('setChats', sessionV2.getChats(isFilter))
    commit('setTotalChats', sessionV2.getTotalChats(version))
    commit('setTotalGroups', sessionV2.getTotalGroups(version))
    commit('setFilterLoading', false)
  },
  async createTag(store, data) {
    const resp = await this.$axios.request({
      method: 'post',
      url: '/chat/tags',
      data: data
    })
    return resp
  },
  async assignTag({ commit }, data) {
    const resp = await this.$axios.request({
      method: 'post',
      url: '/chat/tags/assign',
      data: data
    })
    return resp.data
  },
  async getMe({ commit }, machine_id) {
    const resp = await this.$axios.request({
      method: 'get',
      url: '/chat/me',
      params: { machine_id: machine_id }
    })
    if (resp.data) {
      commit('setMe', resp.data)
    }
  },
  async updateAttendant({ state, commit }, data) {
    const resp = await this.$axios.request({
      method: 'put',
      url: `/chat/attendants`,
      data: data
    })
    return resp.data
  },
  async createAttendant({ state, commit }, data) {
    const resp = await this.$axios.request({
      method: 'post',
      url: '/chat/attendants',
      data: data
    })
    return resp
  },
  async removeAttendant({ state, commit, rootState }, id) {
    const resp = await this.$axios.request({
      method: 'delete',
      url: '/chat/attendants',
      params: { id: id }
    })
    sessionV2.setAttendances(resp.data)
    sessionV2.removeAttendancesFromAttendant(resp.data?.attendances || [])
    return resp.data
  },
  async loadChatsLeads({ dispatch, commit }, machine_id) {
    commit('setLoadingChatLeads', true)
    const resp = await this.$axios.request({
      method: 'get',
      url: `/leads/chat`,
      params: { machine_id: machine_id }
    })
    commit('setChatLeads', Object.assign({}, resp.data))
    sessionV2.setChatLeads(resp.data)
    dispatch('refreshChats', {})
    commit('setLoadingChatLeads', false)
  },
  async loadTags({ state, commit }, machine_id) {
    const resp = await this.$axios.request({
      method: 'get',
      url: '/chat/tags',
      params: { machine_id: machine_id }
    })
    commit('setTags', resp.data)
  },
  showContactView({ state, commit }, contactView) {
    commit('showContactView', contactView)
  },
  setLoadingLead({ commit }, loading) {
    commit('setLoadingLead', loading)
  },
  setLastReceivedMessage({ commit }, message) {
    commit('setLastReceivedMessage', message)
  },
  setLastReceivedMedia({ commit }, media) {
    commit('setLastReceivedMedia', media)
  },
  setLoadingStatus({ commit }, loadingStatus) {
    commit('setLoadingStatus', loadingStatus)
  },
  setLoadingInfo({ commit }, loadingInfo) {
    commit('setLoadingInfo', loadingInfo)
  }
}
