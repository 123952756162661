class Chat {
  constructor(chat) {
    this.id = chat.id
    this.msgs = chat.msgs
    this.preloadTotalMsgs = chat.preloadTotalMsgs || 1
    this.unreadCount = chat.unreadCount
    this.archive = chat.archive
    this.name = chat.name
    this.notSpam = chat.notSpam
    this.pendingMsgs = chat.pendingMsgs
    this.timestamp = chat.timestamp
    this.unreadCount = chat.unreadCount
    this.profilePicThumbObj = chat.profilePicThumbObj
    this.attendance = null
    this.chat_attendance = null
    this.chat_input = null
  }

  addMessage(message) {
    this.msgs[message.id] = message
    this.setReadCount(message.chat.unreadCount)
  }

  setProfilePicThumbObj(profilePicThumbObj) {
    this.profilePicThumbObj = profilePicThumbObj
  }

  setReadCount(value) {
    this.unreadCount = value
  }
  clear() {
    this.msgs = {}
  }
  update(attr, value) {
    this[attr] = values
  }
  setMessages(data) {
    this.msgs = data.data
    this.preloadTotalMsgs = data.total
  }
  setAckMessage(message) {
    let key = message.id.includes('_') ? message.id.split('_')[2] : message.id
    let msg = this.msgs[key]
    if (msg) msg.ack = message.ack
  }
  getMessage(messageId) {
    return this.msgs[messageId]
  }
  setMessageLoaded(msgId, loaded) {
    let msg = this.msgs[msgId]
    if (msg) {
      msg.loaded = loaded
    }
  }
  setChatMessageInput(message) {
    this.chat_input = message
  }
  setAttendance(attendance) {
    this.attendance = attendance
  }
  setChatAttendance(chat) {
    this.chat_attendance = chat
  }
}

export default Chat
