import axios from 'axios'

export const state = () => ({
  unfiltered: {},
  execution: {},
  scheduller: {},
  finalized: {},
  broadcast: {}
})

export const mutations = {
  set_unfiltered(state, list) {
    state.unfiltered = list
  },
  set_execution(state, list) {
    state.execution = list
  },
  set_scheduller(state, list) {
    state.scheduller = list
  },
  set_finalized(state, list) {
    state.finalized = list
  },
  set_broadcast(state, broadcast) {
    state.broadcast = broadcast
  }
}

export const actions = {
  async get({ commit }, broadcastId) {
    const response = await this.$axios.request({
      url: `broadcasts/${broadcastId}`
    })
    commit('set_broadcast', response.data)
    return response.data
  },
  async load({ commit }, params) {
    const response = await this.$axios.request({
      url: 'broadcasts',
      params: params
    })
    if (params['status'] == 1) {
      commit('set_scheduller', response.data)
    } else if (params['status'] == 2) {
      commit('set_execution', response.data)
    } else {
      commit('set_finalized', response.data)
    }

    if (!params['status']) {
      commit('set_unfiltered', response.data)
    }
    return response
  },
  async cancel({ commit }, broadcastId) {
    return await this.$axios.request({
      method: 'delete',
      url: `broadcasts/${broadcastId}`
    })
  },
  async create({ commit }, [data, filters]) {
    return await this.$axios.request({
      method: 'post',
      data: data,
      params: filters,
      url: `broadcasts`
    })
  },
  async update({ commit }, { broadcastId, data }) {
    return await this.$axios.request({
      method: 'put',
      data: data,
      url: `broadcasts/${broadcastId}`
    })
  },
  async getTrigger({ commit }, broadcastId) {
    return await this.$axios.request({
      url: `broadcasts/detail/${broadcastId}/triggers`
    })
  },
  async updateTrigger({ commit }, { broadcastId, onclick, onresponse }) {
    return await this.$axios.request({
      method: 'put',
      data: {
        id: broadcastId,
        onclick,
        onresponse
      },
      url: `broadcasts/detail/${broadcastId}/triggers`
    })
  },
  async loadBroadcastInExecution({ commit }, [broadcastId]) {
    const response = await this.$axios.request({
      url: `broadcasts/execution/${broadcastId}`
    })
    commit('set_broadcast', response.data)
  },
  async getAccessUploadFile({ commit }, [filename]) {
    const { data } = await this.$axios.request({
      url: `broadcasts/file`,
      params: { filename }
    })
    return data
  },
  async sendAccessUploadFile({ commit }, [response, file]) {
    var formData = new FormData()
    Object.keys(response.data.fields).forEach(key => {
      formData.append(key, response.data.fields[key])
    })
    formData.append('file', file)
    return await axios.create().post(response.data.url, formData, {
      'Content-Type': 'multipart/form-data'
    })
  },
  async downloadFile({ commit }, [file_url, mimetype]) {
    const resp = await axios.create().get(file_url, {
      responseType: 'arraybuffer'
    })
    let file = btoa(
      new Uint8Array(resp.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ''
      )
    )
    if (mimetype.includes('audio')) return resp.data ? file : null
    return resp.data ? `data:${mimetype};base64,${file}` : null
  },
  async loadCsv({ commit }, broadcastId) {
    const response = await this.$axios.request({
      url: `broadcasts/${broadcastId}/csv`
    })
    return response
  },
}
