export const state = () => ({
  subscription: {},
  user: {}
})

export const mutations = {
  subscription(state, subscription) {
    state.subscription = subscription
  },
  user(state, user) {
    state.user = user
  }
}

export const actions = {
  async load_user({ commit }, [user_id]) {
    const resp = await this.$axios.request({
      method: 'get',
      url: `/users/${user_id}`
    })
    commit('user', resp.data)
  },
  async load_subscription({ commit }) {
    const resp = await this.$axios.request({
      method: 'get',
      url: `/users/subscription`
    })
    commit('subscription', resp.data)
  },
  async update_password({ commit }, data) {
    const resp = await this.$axios.request({
      method: 'put',
      url: `/users/`,
      data: data
    })
    commit('user', resp.data)
  },
  async update_user({ commit }, [user_id, data]) {
    const resp = await this.$axios.request({
      method: 'put',
      url: `/users/${user_id}`,
      data: data
    })
    commit('user', resp.data)
  },
  async recovery_password_email({ commit }, data) {
    return await this.$axios.request({
      method: 'post',
      url: '/users/reset_password/',
      data: data
    })
  },
  async createPending({ commit }, data) {
    return await this.$axios.request({
      method: 'post',
      url: '/pendings/',
      data: data
    })
  },
  async importContacts({ commit }, data) {
    return await this.$axios.request({
      method: 'post',
      url: '/lead/import',
      data: data
    })
  },
  async getStatusPending({ commit }) {
    const response = await this.$axios.request({
      method: 'get',
      url: '/lead/import'
    })
    return response?.data || {}
  },
  async getUserPending({ commit }) {
    const response = await this.$axios.request({
      method: 'get',
      url: '/pendings/'
    })
    return response?.data || {}
  }
}
