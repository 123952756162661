export default function ({ $auth, route, redirect }) {
  const safeRoutes = [
    $auth.options.redirect.login,
    "/atendente",
    "/register/free",
    "/register/auth/",
    "/recovery",
    "/qrcode",
    "forgot-password",
    "signup"
  ]
  if (!safeRoutes.find((safe) => route.path?.includes(safe)) && !$auth.loggedIn) {
    const options = ["/", "/login"].includes(route.path) ? {} : { redirect: route.path }
    redirect($auth.options.redirect.login, options)
  }
}