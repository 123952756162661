export default {
  computed: {
    zap() {
      return this.$store.state.zaps.zap || {}
    },
    regexUrl() {
      return new RegExp(
        '^(([^\0]+)?http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
      )
    },
    version_app() {
      return window.localStorage.getItem('auth.strategy') == 'chat' &&
        this.$route.name.includes('lite') &&
        this.zap?.version === 10
        ? 'lite-messaging-uuid'
        : 'livechat-uuid'
    }
  },
  methods: {
    replaceSpecialChars(text) {
      if (text && typeof text === 'string') {
        text = text.toLowerCase()
        text = text.replace(new RegExp('[ÁÀÂÃãáàâ]', 'gi'), 'a')
        text = text.replace(new RegExp('[ÉÈÊẽéèê]', 'gi'), 'e')
        text = text.replace(new RegExp('[ÍÌÎíìĩî]', 'gi'), 'i')
        text = text.replace(new RegExp('[ÓÒÔÕóòõô]', 'gi'), 'o')
        text = text.replace(new RegExp('[ÚÙÛúùûũ]', 'gi'), 'u')
        text = text.replace(new RegExp('[Çç]', 'gi'), 'c')
      }
      return text
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        var r = (Math.random() * 16) | 0
        var v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    },
    replaceAll(target, search, replacement) {
      return target.split(search).join(replacement)
    },
    findReplace(text, format, token) {
      let toformat = ''
      let count = 0
      for (let i = 0; i < text.length; i++) {
        if (text[i] === token) {
          count++
        }
        if (count == 1) {
          toformat += text[i]
        }
        if (count == 2) {
          toformat += text[i]
          break
        }
      }
      text = text.replace(
        toformat,
        `<${format}> ${toformat.split(token).join('')}</${format}>`
      )
      return text
    },
    parseFormatting(text, format, token, size) {
      for (let i = 0; i < Math.floor(size / 2); i++)
        text = this.findReplace(text, format, token)

      return text
    },
    getFormattedText(message) {
      let text = message || ''
      text = this.replaceAll(text, '\u200d', '')
      text = this.replaceAll(text, '\u200f', '')
      text = this.replaceAll(text, '\n', ' \n')
      text = this.replaceAll(text, '↵', ' \n')

      var message_split = text.split(' ')
      for (var i = 0; i < message_split.length; i++) {
        if (this.regexUrl.test(message_split[i])) {
          text = text.replace(
            message_split[i],
            `<a style="color: #4a66ce;" href="${
              message_split[i]
            }" target="_blank">` +
              message_split[i] +
              '</a>'
          )
        }
      }
      let size = text.split('*').length - 1
      if (size > 1) {
        text = this.parseFormatting(text, 'b', '*', size)
      }
      size = text.split('_').length - 1
      if (size > 1 && !text.includes('_blank')) {
        text = this.parseFormatting(text, 'i', '_', size)
      }
      size = text.split('~').length - 1
      if (size > 1) {
        text = this.parseFormatting(text, 'strike', '~', size)
      }
      return text
    }
  }
}
