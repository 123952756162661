export const state = () => ({
  list: [],
  templates: [],
  template: {},
  categories: []
})

export const mutations = {
  set(state, list) {
    state.list = list
  },
  set_templates(state, templates) {
    state.templates = templates
  },
  set_template(state, template) {
    state.template = template
  },
  set_categories(state, categories) {
    state.categories = categories
  }
}

export const actions = {
  async create({ commit, dispatch }, { zapId, data }) {
    const resp = await this.$axios.request({
      method: 'post',
      url: 'funnels',
      data: data
    })
    dispatch('load', zapId)
  },
  async load({ commit }, zapId) {
    if (!zapId) {
      return
    }
    const resp = await this.$axios.request({
      url: 'funnels',
      params: {
        machine_id: zapId
      }
    })
    commit('set', resp.data)
  },
  async edit({ commit }, { data, funnelId }) {
    const response = await this.$axios.request({
      method: 'put',
      data: data,
      url: `funnels/detail/${funnelId}`
    })
  },
  async remove({ commit }, funnelId) {
    return await this.$axios.request({
      method: 'delete',
      url: `funnels/detail/${funnelId}`
    })
  },
  async order({ commit }, { data, zapId }) {
    const response = await this.$axios.request({
      method: 'put',
      data: data,
      url: `funnels/order/${zapId}`
    })
  },
  async createfromtemplate({ commit }, template) {
    const resp = await this.$axios.request({
      method: 'post',
      url: 'funnels/templates',
      data: template
    })
  },
  async loadtemplates({ commit }) {
    const resp = await this.$axios.request({
      url: 'funnels/templates',
      method: 'get'
    })
    commit('set_templates', resp.data)
  },
  async loadtemplate({ commit }, id) {
    const resp = await this.$axios.request({
      url: `funnels/templates/${id}`,
      method: 'get'
    })
    commit('set_template', resp.data)
    return resp
  },
  async loadcategories({ commit }) {
    const resp = await this.$axios.request({
      url: 'categories',
      method: 'get'
    })
    commit('set_categories', resp.data)
  }
}
