import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2d15cebe = () => interopDefault(import('..\\pages\\atendente\\index.vue' /* webpackChunkName: "pages/atendente/index" */))
const _426e94e8 = () => interopDefault(import('..\\pages\\dashboard\\index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _053b44da = () => interopDefault(import('..\\pages\\help\\index.vue' /* webpackChunkName: "pages/help/index" */))
const _09a00a08 = () => interopDefault(import('..\\pages\\lite.vue' /* webpackChunkName: "pages/lite" */))
const _2cfb2687 = () => interopDefault(import('..\\pages\\lite\\messaging\\new\\_phone.vue' /* webpackChunkName: "pages/lite/messaging/new/_phone" */))
const _7fddafdc = () => interopDefault(import('..\\pages\\lite\\messaging\\_uuid.vue' /* webpackChunkName: "pages/lite/messaging/_uuid" */))
const _00e53292 = () => interopDefault(import('..\\pages\\livechat.vue' /* webpackChunkName: "pages/livechat" */))
const _17084d10 = () => interopDefault(import('..\\pages\\livechat\\_uuid.vue' /* webpackChunkName: "pages/livechat/_uuid" */))
const _789dd6d2 = () => interopDefault(import('..\\pages\\login\\index.vue' /* webpackChunkName: "pages/login/index" */))
const _cf3e2ab4 = () => interopDefault(import('..\\pages\\messaging.vue' /* webpackChunkName: "pages/messaging" */))
const _3988884e = () => interopDefault(import('..\\pages\\messaging\\new\\_phone.vue' /* webpackChunkName: "pages/messaging/new/_phone" */))
const _0278a2e4 = () => interopDefault(import('..\\pages\\messaging\\_uuid.vue' /* webpackChunkName: "pages/messaging/_uuid" */))
const _7c9f38fe = () => interopDefault(import('..\\pages\\old_onboarding.vue' /* webpackChunkName: "pages/old_onboarding" */))
const _f29c0a0e = () => interopDefault(import('..\\pages\\onboarding\\index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _20d0a85a = () => interopDefault(import('..\\pages\\payment\\index.vue' /* webpackChunkName: "pages/payment/index" */))
const _cdb9af92 = () => interopDefault(import('..\\pages\\subscription\\index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _698229a3 = () => interopDefault(import('..\\pages\\auth\\forgot-password\\index.vue' /* webpackChunkName: "pages/auth/forgot-password/index" */))
const _0a05526a = () => interopDefault(import('..\\pages\\auth\\signin\\index.vue' /* webpackChunkName: "pages/auth/signin/index" */))
const _478ceb34 = () => interopDefault(import('..\\pages\\auth\\signup\\index.vue' /* webpackChunkName: "pages/auth/signup/index" */))
const _2d4fa0d6 = () => interopDefault(import('..\\pages\\broadcast\\new\\index.vue' /* webpackChunkName: "pages/broadcast/new/index" */))
const _42710588 = () => interopDefault(import('..\\pages\\login\\forgot_password.vue' /* webpackChunkName: "pages/login/forgot_password" */))
const _7d17c9da = () => interopDefault(import('..\\pages\\login\\page.vue' /* webpackChunkName: "pages/login/page" */))
const _0f66f09d = () => interopDefault(import('..\\pages\\register\\free.vue' /* webpackChunkName: "pages/register/free" */))
const _2ee0ae07 = () => interopDefault(import('..\\pages\\subscription\\change\\index.vue' /* webpackChunkName: "pages/subscription/change/index" */))
const _55fa41ba = () => interopDefault(import('..\\pages\\zap\\attention.vue' /* webpackChunkName: "pages/zap/attention" */))
const _4a6d8747 = () => interopDefault(import('..\\pages\\zap\\benefits.vue' /* webpackChunkName: "pages/zap/benefits" */))
const _6f658d5e = () => interopDefault(import('..\\pages\\zap\\first_access.vue' /* webpackChunkName: "pages/zap/first_access" */))
const _2a66d0fa = () => interopDefault(import('..\\pages\\zap\\ImportContacts.vue' /* webpackChunkName: "pages/zap/ImportContacts" */))
const _6c46ab52 = () => interopDefault(import('..\\pages\\zap\\qrcode_v2.vue' /* webpackChunkName: "pages/zap/qrcode_v2" */))
const _4dd40884 = () => interopDefault(import('..\\pages\\zap\\templates.vue' /* webpackChunkName: "pages/zap/templates" */))
const _6af65a74 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _9b29e0e4 = () => interopDefault(import('..\\pages\\index\\index.vue' /* webpackChunkName: "pages/index/index" */))
const _20df0dfd = () => interopDefault(import('..\\pages\\index\\broadcast.vue' /* webpackChunkName: "pages/index/broadcast" */))
const _15f8c1ba = () => interopDefault(import('..\\pages\\index\\configuracao.vue' /* webpackChunkName: "pages/index/configuracao" */))
const _279fc8d3 = () => interopDefault(import('..\\pages\\index\\leads.vue' /* webpackChunkName: "pages/index/leads" */))
const _83a9ef86 = () => interopDefault(import('..\\pages\\index\\myaccount.vue' /* webpackChunkName: "pages/index/myaccount" */))
const _35b4f0f8 = () => interopDefault(import('..\\pages\\index\\sequencia.vue' /* webpackChunkName: "pages/index/sequencia" */))
const _0a779d3c = () => interopDefault(import('..\\pages\\index\\chat\\messaging\\_uuid.vue' /* webpackChunkName: "pages/index/chat/messaging/_uuid" */))
const _4b20b75a = () => interopDefault(import('..\\pages\\register\\auth\\_token.vue' /* webpackChunkName: "pages/register/auth/_token" */))
const _568923fe = () => interopDefault(import('..\\pages\\zap\\simulator\\_id.vue' /* webpackChunkName: "pages/zap/simulator/_id" */))
const _abb70054 = () => interopDefault(import('..\\pages\\qrcode\\_token.vue' /* webpackChunkName: "pages/qrcode/_token" */))
const _25d573dd = () => interopDefault(import('..\\pages\\recovery\\_token.vue' /* webpackChunkName: "pages/recovery/_token" */))
const _1522042a = () => interopDefault(import('..\\pages\\register\\_token.vue' /* webpackChunkName: "pages/register/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/atendente",
    component: _2d15cebe,
    name: "atendente"
  }, {
    path: "/dashboard",
    component: _426e94e8,
    name: "dashboard"
  }, {
    path: "/help",
    component: _053b44da,
    name: "help"
  }, {
    path: "/lite",
    component: _09a00a08,
    name: "lite",
    children: [{
      path: "messaging/new/:phone?",
      component: _2cfb2687,
      name: "lite-messaging-new-phone"
    }, {
      path: "messaging/:uuid?",
      component: _7fddafdc,
      name: "lite-messaging-uuid"
    }]
  }, {
    path: "/livechat",
    component: _00e53292,
    name: "livechat",
    children: [{
      path: ":uuid?",
      component: _17084d10,
      name: "livechat-uuid"
    }]
  }, {
    path: "/login",
    component: _789dd6d2,
    name: "login"
  }, {
    path: "/messaging",
    component: _cf3e2ab4,
    name: "messaging",
    children: [{
      path: "new/:phone?",
      component: _3988884e,
      name: "messaging-new-phone"
    }, {
      path: ":uuid?",
      component: _0278a2e4,
      name: "messaging-uuid"
    }]
  }, {
    path: "/old_onboarding",
    component: _7c9f38fe,
    name: "old_onboarding"
  }, {
    path: "/onboarding",
    component: _f29c0a0e,
    name: "onboarding"
  }, {
    path: "/payment",
    component: _20d0a85a,
    name: "payment"
  }, {
    path: "/subscription",
    component: _cdb9af92,
    name: "subscription"
  }, {
    path: "/auth/forgot-password",
    component: _698229a3,
    name: "auth-forgot-password"
  }, {
    path: "/auth/signin",
    component: _0a05526a,
    name: "auth-signin"
  }, {
    path: "/auth/signup",
    component: _478ceb34,
    name: "auth-signup"
  }, {
    path: "/broadcast/new",
    component: _2d4fa0d6,
    name: "broadcast-new"
  }, {
    path: "/login/forgot_password",
    component: _42710588,
    name: "login-forgot_password"
  }, {
    path: "/login/page",
    component: _7d17c9da,
    name: "login-page"
  }, {
    path: "/register/free",
    component: _0f66f09d,
    name: "register-free"
  }, {
    path: "/subscription/change",
    component: _2ee0ae07,
    name: "subscription-change"
  }, {
    path: "/zap/attention",
    component: _55fa41ba,
    name: "zap-attention"
  }, {
    path: "/zap/benefits",
    component: _4a6d8747,
    name: "zap-benefits"
  }, {
    path: "/zap/first_access",
    component: _6f658d5e,
    name: "zap-first_access"
  }, {
    path: "/zap/ImportContacts",
    component: _2a66d0fa,
    name: "zap-ImportContacts"
  }, {
    path: "/zap/qrcode_v2",
    component: _6c46ab52,
    name: "zap-qrcode_v2"
  }, {
    path: "/zap/templates",
    component: _4dd40884,
    name: "zap-templates"
  }, {
    path: "/",
    component: _6af65a74,
    children: [{
      path: "",
      component: _9b29e0e4,
      name: "index"
    }, {
      path: "broadcast",
      component: _20df0dfd,
      name: "index-broadcast"
    }, {
      path: "configuracao",
      component: _15f8c1ba,
      name: "index-configuracao"
    }, {
      path: "leads",
      component: _279fc8d3,
      name: "index-leads"
    }, {
      path: "myaccount",
      component: _83a9ef86,
      name: "index-myaccount"
    }, {
      path: "sequencia",
      component: _35b4f0f8,
      name: "index-sequencia"
    }, {
      path: "chat/messaging/:uuid?",
      component: _0a779d3c,
      name: "index-chat-messaging-uuid"
    }]
  }, {
    path: "/register/auth/:token?",
    component: _4b20b75a,
    name: "register-auth-token"
  }, {
    path: "/zap/simulator/:id?",
    component: _568923fe,
    name: "zap-simulator-id"
  }, {
    path: "/qrcode/:token?",
    component: _abb70054,
    name: "qrcode-token"
  }, {
    path: "/recovery/:token?",
    component: _25d573dd,
    name: "recovery-token"
  }, {
    path: "/register/:token?",
    component: _1522042a,
    name: "register-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
