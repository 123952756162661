export const state = () => ({
  list: []
})

export const mutations = {
  set(state, list) {
    state.list = list
  }
}

export const actions = {
  async load({ commit }, params) {
    const resp = await this.$axios.request({
      url: 'links/',
      params: params
    })
    commit('set', resp.data)
  },
  async create({ commit }, data) {
    return await this.$axios.request({
      method: 'post',
      url: 'links/',
      data: data
    })
  },
  async update({ commit }, [linkId, data]) {
    return await this.$axios.request({
      method: 'put',
      url: `links/${linkId}`,
      data: data
    })
  },
  async delete({ commit }, linkId) {
    return await this.$axios.request({
      method: 'delete',
      url: `links/${linkId}`
    })
  }
}
