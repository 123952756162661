<template>
  <div class="navbar">
    <div class="navbar-fixed">
      <div class="nav-content-wrapper">
        <div class="navbar-content">
          <h5 class="navbar-logo-wrapper">
            <a class="desktop-only" href="javascript:void(0)" @click="toggleMenu">
              <svg
                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none"
              >
                <path d="M2 4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4C22 4.26522 21.8946 4.51957 21.7071 4.70711C21.5196 4.89464 21.2652 5 21 5H3C2.73478 5 2.48043 4.89464 2.29289 4.70711C2.10536 4.51957 2 4.26522 2 4ZM3 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13ZM3 21H21C21.2652 21 21.5196 20.8946 21.7071 20.7071C21.8946 20.5196 22 20.2652 22 20C22 19.7348 21.8946 19.4804 21.7071 19.2929C21.5196 19.1054 21.2652 19 21 19H3C2.73478 19 2.48043 19.1054 2.29289 19.2929C2.10536 19.4804 2 19.7348 2 20C2 20.2652 2.10536 20.5196 2.29289 20.7071C2.48043 20.8946 2.73478 21 3 21Z" fill="#13172A" />
              </svg>
            </a>
            <nuxt-link :to="{ name: 'index' }" :disabled="zap === undefined">
              <img class="navbar-logo" src="/assets/img/clickzap_logo.svg" alt="clickzap logo">
            </nuxt-link>
          </h5>
        </div>
        <span v-if="showNavigation" class="menu-wrapper">
          <SubscriptionBadge v-if="!hiddenSubscriptionBadge" style="margin-right: 24px;" />
          <Dropdown>
            <template slot="toggler">
              <a class="menu-btn" href="javascript:void(0)">
                <span class="username">{{ username }}</span>
                <svg
                  width="12" height="12" viewBox="0 0 12 12"
                  fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.49999 4H3.49999C3.40489 3.99996 3.31175 4.02703 3.2315 4.07806C3.15125 4.12908 3.08721 4.20193 3.0469 4.28806C3.00659 4.37419 2.99168 4.47003 3.00391 4.56434C3.01615 4.65865 3.05503 4.74751 3.11599 4.8205L5.61599 7.8205C5.66291 7.87677 5.72163 7.92204 5.78799 7.95311C5.85434 7.98417 5.92672 8.00027 5.99999 8.00027C6.07325 8.00027 6.14563 7.98417 6.21199 7.95311C6.27834 7.92204 6.33706 7.87677 6.38399 7.8205L8.88399 4.8205C8.94494 4.74751 8.98382 4.65865 8.99606 4.56434C9.0083 4.47003 8.99338 4.37419 8.95307 4.28806C8.91276 4.20193 8.84873 4.12908 8.76847 4.07806C8.68822 4.02703 8.59508 3.99996 8.49999 4Z" fill="#283651" />
                </svg>
              </a>
            </template>
            <DropdownContent>
              <DropdownItem
                v-for="link in links"  
                :key="link.name"
                :to="{ name: link.name }"
                class="mobile-only"
                @click.native="toggleMenu"
              >
                {{ link.label }}
              </DropdownItem>
              <DropdownItem :to="{ name : 'index-myaccount' }" @click.native="toggleMenu">
                Minha Conta
              </DropdownItem>
              <DropdownItem to="#" @click.native="onLogout">
                Sair
              </DropdownItem>
            </DropdownContent>
          </Dropdown>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import SubscriptionBadge from './SubscriptionBadge'
import Dropdown from '@/components/base/dropdown'
import DropdownItem from '@/components/base/dropdown/dropdown-item'
import DropdownContent from '@/components/base/dropdown/dropdown-content'

const links = [
  { label: 'Dashboard', name: 'index' },
  { label: 'Fluxos', name: 'index-sequencia' },
  { label: 'Transmissão', name: 'index-broadcast' },
  { label: 'Contatos', name: 'index-leads' },
  { label: 'Live Chat', name: 'livechat-uuid' },
  { label: 'Configurações', name: 'index-configuracao' }
]

export default {
  components: {
    SubscriptionBadge,
    Dropdown,
    DropdownItem,
    DropdownContent
  },
  props: {
    onLogout: { type: Function, required: false, default: undefined },
    hiddenSubscriptionBadge: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      menu: false,
      links
    }
  },
  computed: {
    ...mapGetters(['loggedInUser']),
    username() {
      return this.loggedInUser ? this.loggedInUser.name.split(' ')[0] : '-'
    },
    zap() {
      return this.$store.state.zaps.zap || {}
    },
    showNavigation() {
      return (
        !['payment', 'qrcode-token', 'subscription-change'].includes(
          this.$route.name
        ) &&
        !(
          this.$route.name === 'broadcast-new' &&
          this.$route.query?.source === 'onboarding'
        ) &&
        !this.loggedInUser?.attendant_id
      )
    }
  },
  methods: {
    toggleMenu() {
      this.menu = this.menu ? false : true
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.mobile-only {
  display: inherit;
  @include media-query($small-breakpoint) {
    display: none;
  }
}

.desktop-only {
  display: none !important;
  @include media-query($small-breakpoint) {
    display: inherit !important;
  }
}

.menu-wrapper {
  display: flex;
  align-items: center;
}

.username {
  @include LabelSmall;
  font-family: $monserratFontFamily;
  font-weight: 600;
  float: none;
  margin-top: 0;
  margin-right: $scale300;
  color: $neutral500;
}

.menu-btn {
  color: $accent400;
}

.close-btn-wrapper {
  position: absolute;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  z-index: 10;

  .close-btn {
    height: 34px;
    width: 34px;
    color: $accent400;
  }
}

.navbar {
  position: relative;
  height: 64px;
}

.navbar-fixed {
  height: 64px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 201;
  padding: 19px 16px;
  border-bottom: 1px solid $mono200;
  background-color: $white;
}
.container {
  font-size: initial;
}
.nuxt-link-active {
  line-height: 0;
}
.navbar-logo-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: initial;

  a {
    padding: 0 8px;
  }
}
.navbar-logo {
  height: 24px;
  width: 114px;
}

.nav-content-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // max-width: $extra-large-breakpoint;
  margin: 0 auto;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@include media-query($small-breakpoint) {
  .navbar-fixed {
    padding: 19px 24px;
  }
}
</style>
