<template>
  <nuxt-link class="dropdown-item" :to="to" @click.prevent>
    <slot />
  </nuxt-link> 
</template>

<script>
export default {
  name: 'DropdownItem',
  props: {
    to: { type: Object, required: false, default: () => '#' }
  }
}
</script>

<style lang="scss">
@import 'assets/scss/variables';

.dropdown-item {
  @include LabelMedium;
  font-family: $monserratFontFamily;
  display: block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
  text-decoration: none;
  color: $neutral500;
}

.dropdown-item:hover {
  background-color: $mono300;
}
</style>
