export const actions = {
  async remove({ commit }, messageId) {
    return await this.$axios.request({
      method: 'delete',
      url: `messages/detail/${messageId}`
    })
  },
  async show({ commit }, messageId) {
    return await this.$axios.request({
      url: `messages/detail/${messageId}`
    })
  },
  async create({ commit }, data) {
    return await this.$axios.request({
      method: 'post',
      data: data,
      url: 'messages/'
    })
  },
  async update({ commit }, { messageId, data }) {
    return await this.$axios.request({
      method: 'put',
      data: data,
      url: `messages/detail/${messageId}`
    })
  },
  async order({ commit, dispatch }, { data, sequenceId, zapId }) {
    const response = await this.$axios.request({
      method: 'put',
      data: data,
      url: `messages/order/${sequenceId}`
    })
  },
  async getTrigger({ commit }, messageId) {
    return await this.$axios.request({
      url: `messages/detail/${messageId}/triggers`
    })
  },
  async updateTrigger({ commit }, { messageId, onclick, onresponse }) {
    return await this.$axios.request({
      method: 'put',
      data: {
        id: messageId,
        onclick,
        onresponse
      },
      url: `messages/detail/${messageId}/triggers`
    })
  }
}
