<template>
  <div v-show="false" v-if="showHeader">
    <div class="navbar">
      <div class="container pa-0">
        <div class="nav-content-wrapper">
          <div class="navbar-content">
            <span>
              <strong>Novidade: </strong>
              Agora separamos os seus contatos excluídos. <a @click="openModal">Clique aqui para saber mais.</a></span>
          </div>
        </div>
      </div>
    </div>
    <cz-dialog
      :dialog="showModal"
      size="800"
      @close="close"
    >
      <div slot="body">
        <div class="video-content">
          <div class="video-wrapper">
            <iframe
              width="500"
              height="252"
              :src="link"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
      </div>
    </cz-dialog>
  </div>
</template>

<script>
import CzDialog from '@/components/cz-dialog'

export default {
  components: {
    CzDialog
  },
  data() {
    return {
      notificationId: 'excluded_contacts',
      link: 'https://player.vimeo.com/video/839283496',
      showHeader: false,
      showModal: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const notification = await this.$localForage.notifications.getItem(
        this.notificationId
      )
      this.showHeader = !notification?.viewed
    },
    async openModal() {
      await this.$localForage.notifications.setItem(this.notificationId, {
        viewed: true
      })
      this.showModal = true
    },
    async close() {
      this.showHeader = false
      this.showModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/scss/variables';

.navbar {
  height: inherit;
  padding: 11px 20px;
  background: #68cd86;
  border-bottom: 1px solid #42a85f;
}
.navbar a {
  cursor: pointer;
  color: #0073ffb6;
  text-decoration: none;
}

.navbar span {
  text-align: center;
  font-size: 14px;
}
.navbar-logo {
  height: 19px;
  width: 100px;
}

.nav-content-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-list {
  display: flex;
  list-style: none;
  grid-column-gap: 21px;
  padding: 0;
  margin: 0 54px;
}

.nav-item {
  @include LabelSmall;
  text-transform: uppercase;
  color: $mono700;

  display: inline;

  &.active {
    &::after {
      content: ' ';
      display: block;
      padding: 5px;
      margin: -5px;
      border-bottom: 2px solid #6195ed;
    }
  }
}

@include media-query($small-breakpoint) {
  .navbar {
    padding: 19px 16px;
  }
  .container {
    font-size: initial;
  }
  .navbar-logo-wrapper {
    font-size: initial;
  }
  .navbar span {
    font-size: 16px;
  }
  .navbar-logo {
    height: 25px;
    width: 129px;
  }
}

.video-content {
  padding-top: 20px;
}
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
