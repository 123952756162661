export const state = () => ({
  list: {
    data: [],
    total: 0,
  },
  listInactive: {
    data: [],
    total: 0
  },
  lead: {},
  count_leads_gain: [],
  activities: []
})

export const mutations = {
  set(state, list) {
    state.list = list
  },
  setInactive(state, list) {
    state.listInactive = list
  },
  lead(state, lead) {
    state.lead = lead
  },
  cleanLead(state) {
    state.lead = {}
  },
  setCountLeads(state, count) {
    state.count_leads_gain = count
  },
  setActivities(state, activities) {
    state.activities = activities
  }
}

export const actions = {
  async load({ commit }, params) {
    const resp = await this.$axios.request({
      url: 'leads/',
      params: params
    })
    commit('set', resp.data)

    const respInactive = await this.$axios.request({
      url: 'leads/',
      params: {
        ...params,
        active: false
      }
    })
    commit('setInactive', respInactive.data)
    return resp.data
  },
  async detail({ commit }, params) {
    commit('cleanLead')
    const resp = await this.$axios.request({
      url: 'leads/detail',
      params: params
    })
    commit('lead', resp.data)
  },
  async exportCsv({ commit }, params) {
    return await this.$axios.request({
      url: 'leads/actions/export_csv',
      params: params
    })
  },
  async remove({ commit }, { leadId, machineId }) {
    return await this.$axios.request({
      method: 'delete',
      url: `leads/${machineId}/${leadId}`
    })
  },
  async updateLead({ commit }, [leadId, lead]) {
    return await this.$axios.request({
      method: 'put',
      url: `/leads/detail/${leadId}`,
      data: lead
    })
  },
  async removeMany({ commit }, { machineId, filters }) {
    return await this.$axios.request({
      method: 'post',
      url: `leads/${machineId}/delete`,
      data: filters
    })
  },
  async activate({ commit }, { leadId, machineId }) {
    return await this.$axios.request({
      method: 'post',
      url: `leads/${machineId}/${leadId}/activate`
    })
  },
  async activateMany({ commit }, { machineId, filters }) {
    return await this.$axios.request({
      method: 'post',
      url: `leads/${machineId}/activate`,
      data: filters
    })
  },
  async leadsGain({ commit }, [period]) {
    const response = await this.$axios.request({
      method: 'get',
      url: `leads/gain`,
      params: { period: period }
    })
    commit('setCountLeads', response.data)
  },
  async leadsActivities({ commit }, filters) {
    const response = await this.$axios.request({
      method: 'get',
      url: `leads/activities`,
      params: filters
    })
    commit('setActivities', response.data)
  },
  async asignTagsToLeads({ commit }, [filters, data]) {
    const response = await this.$axios.request({
      method: 'put',
      url: `leads/assign`,
      params: filters,
      data: data
    })
    return response.data
  }
}
