<template>
  <transition name="dropdown-content">
    <div v-if="active" class="dropdown-content-wrapper">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DropdownContent',
  inject: ['sharedState'],
  computed: {
    active() {
      return this.sharedState.active
    }
  }
}
</script>

<style lang="scss">
@import 'assets/scss/variables';

.dropdown-content-enter-active,
.dropdown-content-leave-active {
  transition: all 0.2s;
}

.dropdown-content-enter,
.dropdown-content-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}

.dropdown-content-wrapper {
  position: absolute;
  right: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  width: 12rem;
  background-color: $neutral100;
  transform-origin: top right;
  border: 1px solid $neutral300;
}
</style>
