export const state = () => ({
  list: []
})

export const mutations = {
  set(state, list) {
    state.list = list
  }
}

export const actions = {
  async create({ commit, dispatch }, { data }) {
    const resp = await this.$axios.request({
      method: 'post',
      url: 'sequences',
      data: data
    })
  },
  async load({ commit }, zapId) {
    const resp = await this.$axios.request({
      url: 'sequences',
      params: {
        machine_id: zapId
      }
    })
    commit('set', resp.data)
  },
  async edit({ commit, dispatch }, { data, sequenceId }) {
    const response = await this.$axios.request({
      method: 'put',
      data: data,
      url: `sequences/detail/${sequenceId}`
    })
  },
  async remove({ commit, dispatch }, sequenceId) {
    return await this.$axios.request({
      method: 'delete',
      url: `sequences/detail/${sequenceId}`
    })
  },
  async order({ commit, dispatch }, { data, zapId }) {
    const response = await this.$axios.request({
      method: 'put',
      data: data,
      url: `sequences/order/${zapId}`
    })
    dispatch('load', zapId)
  }
}
