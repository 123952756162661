import axios from 'axios'

export const actions = {
  async uploadFile(state, [filename, file, onUploadProgress]) {
    const response = await this.$axios.request({
      url: `upload/presigned`,
      method: 'post',
      data: {
        filename: filename
      }
    })
    var formData = new FormData()
    Object.keys(response.data.fields).forEach(key => {
      formData.append(key, response.data.fields[key])
    })
    formData.append('file', file)
    await axios.create().post(response.data.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onUploadProgress
    })
    return response.data
  }
}
