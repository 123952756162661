<template>
  <div v-if="dialog">
    <v-dialog
      v-model="dialog"
      :width="size"
      :height="size"
      :hide-overlay="hideOverlay"
      :persistent="persistent"
    >
      <slot name="all">
        <v-card>
          <v-btn
            flat
            icon
            :style="`position: absolute; top: ${title == '' ? '-10px' : '2px'}; right: 0;`"
            @click="close"
          >
            <v-icon
              small
              color="gray"
            >
              close
            </v-icon>
          </v-btn>
          <v-card-title
            v-if="title != ''"
            class="justify-center"
          >
            <slot name="header">
              <h4>
                {{ title }}
              </h4>
            </slot>
          </v-card-title>
          <v-card-text
            style="color:#525252;"
            class="justify-center"
          >
            <slot name="body">
              <p
                class="justify-center"
                :style="`text-align: center; margin-top: ${title == '' ? '30px' : '-10px'};`"
              >
                <strong>
                  {{ body }}
                </strong>
              </p>
            </slot>
            <div style="padding-top: 20px">
              <slot name="actions" />
            </div>
          </v-card-text>
        </v-card>
      </slot>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    origin: {
      type: String,
      default: 'center center'
    },
    hideOverlay: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: true
    },
    dialog: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 400
    },
    title: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
