const swal = require('sweetalert2')

const confirmColor = '#367CFF'
const cancelColor = '#E84747'
// const saveColor = '#01A263'

export default {
  swalMessage,
  swalTitle,
  swalConfirm,
  swalButton,
  swalLoading,
  swalAutoClose,
  swalSuccess,
  swalError
}

// Alerta sem título
async function swalMessage({
  text = '',
  icon = 'warning',
  buttonText = 'OK'
} = {}) {
  return await swal.fire({
    html: text,
    icon: icon,
    confirmButtonText: buttonText?.toUpperCase(),
    confirmButtonColor: confirmColor,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    focusConfirm: false,
    customClass: 'swal2-custom'
  })
}

// Alerta sem texto
async function swalTitle({
  title = '',
  icon = '',
  confirm = 'Sim',
  cancel = 'Cancelar',
  leftColor = cancelColor,
  rightColor = confirmColor,
  reverse = true,
  spacedBtn = false
} = {}) {
  return await swal.fire({
    title: title,
    icon: icon,
    showCancelButton: true,
    confirmButtonColor: rightColor,
    cancelButtonColor: leftColor,
    confirmButtonText: confirm?.toUpperCase(),
    cancelButtonText: cancel?.toUpperCase(),
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    focusConfirm: false,
    reverseButtons: reverse,
    customClass: spacedBtn ? 'swal2-custom-spaced-btn' : 'swal2-custom'
  })
}

// Alerta de Confirmação com 2 opções
async function swalConfirm(
  title = '',
  text = '',
  icon = 'warning',
  confirm = 'Sim',
  cancel = 'Cancelar',
  reverse = true,
  spacedBtn = false
) {
  return await swal.fire({
    title: title,
    html: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonColor: confirmColor,
    cancelButtonColor: cancelColor,
    confirmButtonText: confirm?.toUpperCase(),
    cancelButtonText: cancel?.toUpperCase(),
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    focusConfirm: false,
    reverseButtons: reverse,
    customClass: spacedBtn ? 'swal2-custom-spaced-btn' : 'swal2-custom'
  })
}

// Alerta de aviso ou resposta com 1 botão de confirmação
async function swalButton(
  title = '',
  text = '',
  icon = 'warning',
  buttonText = 'OK'
) {
  return await swal.fire({
    title: title,
    html: text,
    icon: icon,
    confirmButtonText: buttonText?.toUpperCase(),
    confirmButtonColor: confirmColor,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    focusConfirm: false,
    customClass: 'swal2-custom'
  })
}

// Alerta com loading (timer)
async function swalLoading({
  title = '',
  text = '',
  icon = 'warning',
  timer = 2000
} = {}) {
  return await swal.fire({
    title: title,
    html: text,
    icon: icon,
    timer: timer,
    willOpen: () => {
      swal.showLoading()
    },
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    customClass: 'swal2-custom'
  })
}

// Alerta com timer (autoclose)
async function swalAutoClose({
  title = '',
  text = '',
  icon = 'warning',
  timer = 2000
} = {}) {
  return await swal.fire({
    title: title,
    html: text,
    icon: icon,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    timer: timer,
    customClass: 'swal2-custom'
  })
}

// Alerta de Sucesso sem botão com timer (autoclose)
async function swalSuccess({ text = '', timer = 2000 } = {}) {
  return await swal.fire({
    title: 'Sucesso!',
    html: text,
    icon: 'success',
    showConfirmButton: false,
    timer: timer,
    customClass: 'swal2-custom'
  })
}

// Alerta de Erro com botão de confirmação
async function swalError({ text = '', buttonText = 'OK' } = {}) {
  return await swal.fire({
    title: 'Oops!',
    html: text,
    icon: 'error',
    confirmButtonText: buttonText,
    confirmButtonColor: confirmColor,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    focusConfirm: false,
    customClass: 'swal2-custom'
  })
}
